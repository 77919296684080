import React, { memo, useCallback, useEffect, useMemo } from 'react'
import {
  ConditionSlug,
  OnCompleteProps,
  OnTriageProps
} from 'assessment-engine/src'
import AssessmentComponent from '../components/Assessment/AssessmentComponent'
import { useUserContext } from '../contexts/User/userContext'
import { navigate } from 'gatsby'
import { AssessmentTriageUrl } from '../../common/constants'
import { PushToDatalayer, trackUserAction } from '../utils/analytics-tracking'
import UserIdentificationLayer, {
  AuthPage
} from '../components/LayerComponents/UserIdentificationLayer'
import SEO from '../components/SEO'
import SimpleHeader from '../components/Layout/SimpleHeader'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import CountryProvider, {
  Country,
  useCountryContext
} from '../components/common/CountryProvider'
import {
  EmployerTier,
  EmployerType,
  useEmployerContext
} from '../contexts/Employer/employerProvider'
import { currentActiveQuizKey } from '../constants/localStorage'

function IntakeAssessmentsComponent({ pageContext, location }: Props) {
  const {
    user,
    refreshUserState,
    setUserCondition,
    updateUser
  } = useUserContext() as any
  const { employer } = useEmployerContext()
  const { currentCountry } = useCountryContext()

  const [authPage] = useQueryParam(
    'authPage',
    withDefault(StringParam, AuthPage.LOGIN)
  )

  useEffect(() => {
    localStorage.setItem(currentActiveQuizKey, pageContext.slug)
    setUserCondition(pageContext.slug)
  }, [pageContext.slug, setUserCondition])

  useEffect(() => {
    return () => {
      refreshUserState()
    }
  }, [refreshUserState])

  const onTriage = useCallback((props: OnTriageProps) => {
    const triaged = props.triaged
    const riskLevel = props.higherRiskQuestion?.riskLevel
    const condition = props.condition
    const isMentalHealth = condition === ConditionSlug.MENTAL_HEALTH
    let navigateToTriageUrl = false

    if (triaged) {
      if (isMentalHealth && riskLevel === 4 && !props.completed) {
        props.completeAssessment()
      }

      if (props.completed) {
        navigateToTriageUrl = true
      }
    }

    if (navigateToTriageUrl) {
      PushToDatalayer({
        event: 'gtm.risk-level',
        data: riskLevel
      })

      trackUserAction('High Risk Triaged', {
        riskLevel: riskLevel,
        condition
      })

      navigate(AssessmentTriageUrl, {
        state: {
          conditionSlug: condition,
          riskLevel: riskLevel
        }
      })
    }
  }, [])

  const onComplete = useCallback(
    async ({ conditionSlug, triaged }: OnCompleteProps) => {
      if (triaged) return

      refreshUserState({
        type: 'quiz',
        conditionSlug,
        quiz: {
          isComplete: true
        }
      })

      updateUser({ conditionSlug })
      await navigate(`/checkout`)
    },
    [updateUser, refreshUserState]
  )

  const metadata = useMemo(
    () => getAssessmentMetadata({ currentCountry, employer }),
    [currentCountry, employer]
  )

  return (
    <UserIdentificationLayer
      location={location}
      authPageIfNotAuthed={authPage}
      checkOnlyLoggedIn={undefined}
    >
      <SEO
        location={location}
        noIndex={true}
        title={''}
        type={undefined}
        canonicalLink={undefined}
      />
      <SimpleHeader backButton={false} disableNavigation={undefined} />
      <AssessmentComponent
        condition={pageContext.slug}
        location={location}
        onTriage={onTriage}
        onComplete={onComplete}
        metadata={metadata}
        triageEnabled={user.employerSlug !== 'demo-gp'}
        excludedQuestions={[]}
      />
    </UserIdentificationLayer>
  )
}

function IntakeAssessmentsPage(props: Props) {
  return (
    <CountryProvider>
      <IntakeAssessmentsComponent {...props} />
    </CountryProvider>
  )
}

export default memo(IntakeAssessmentsPage)

export const getAssessmentMetadata = ({
  currentCountry,
  employer
}: {
  currentCountry?: Country
  employer?: EmployerType
}) => {
  const autoRespondedQuestions = []

  if (currentCountry) {
    autoRespondedQuestions.push({
      questionId: 'country',
      responseId: currentCountry.countryCode,
      skip: true
    })
  } else if (employer?.countries?.length === 1) {
    autoRespondedQuestions.push({
      questionId: 'country',
      responseId: employer?.countries[0].countryCode,
      skip: true
    })
  } else if (!employer?.countries || employer?.countries?.length === 0) {
    autoRespondedQuestions.push({
      questionId: 'country',
      responseId: 'US',
      skip: true
    })
  }

  if (employer?.tier === EmployerTier.GPDirect) {
    autoRespondedQuestions.push({
      questionId: 'Tier',
      responseId: 'Tier_GPDirect',
      skip: true
    })
  } else {
    autoRespondedQuestions.push({
      questionId: 'Tier',
      responseId: 'Tier_Full',
      skip: true
    })
  }

  employer?.autoRespondedQuestions?.forEach((e) => {
    const questionId = e.question.questionId
    const responseIds = e.responses.map((r) => r.responseId)
    responseIds.forEach((responseId) => {
      autoRespondedQuestions.push({
        questionId,
        responseId,
        skip: true
      })
    })
  })

  return {
    autoRespondedQuestions
  }
}

type Props = {
  pageContext: {
    slug: ConditionSlug
  }
  location: Location
}
